import type { BrandType } from '../../config/index.types'
import { currentBrand } from '../../config'
import { QasaLogo } from '../../assets/logos/qasa/qasa-logo'
import { QasaXBlocketLogo } from '../../assets/logos/blocket/qasa-x-blocket'

import type { LinkItem } from './links'
import { useFooterLinks } from './links'

export type FooterContent = {
  logo: React.FunctionComponent
  descriptionI18nKey?: string
  linkGroups: {
    titleI18nKey?: string
    links: Array<LinkItem | null>
  }[]
}

function BlocketLogo() {
  return <QasaXBlocketLogo height={28} />
}
function StyledQasaLogo() {
  return <QasaLogo height={48} color="pink" />
}

export function useFooterContent(isUserAuthenticated: boolean): FooterContent {
  const LINKS = useFooterLinks()
  const brandConfig: Record<BrandType, FooterContent> = {
    blocket: {
      logo: BlocketLogo,
      descriptionI18nKey: 'description.blocket',
      linkGroups: [
        {
          titleI18nKey: 'link_group_headings.navigation',
          links: [
            LINKS.startPage,
            LINKS.findHome,
            LINKS.findTenant,
            LINKS.findVacation,
            ...(isUserAuthenticated ? [LINKS.messages, LINKS.myListings, LINKS.leases] : []),
            LINKS.help,
            ...(isUserAuthenticated ? [LINKS.logout] : [LINKS.login, LINKS.register]),
            LINKS.sitemap,
          ],
        },
        {
          titleI18nKey: 'link_group_headings.resources',
          links: [LINKS.pricing, LINKS.ourOffer, LINKS.forCompanies],
        },
        {
          titleI18nKey: 'link_group_headings.about_us',
          links: [LINKS.careers, LINKS.pressMedia],
        },
        {
          titleI18nKey: 'link_group_headings.external',
          links: [LINKS.blocket, LINKS.blocketVehicles, LINKS.blocketWork],
        },
      ],
    },
    qasa_finland: {
      logo: StyledQasaLogo,
      linkGroups: [
        {
          links: [
            LINKS.startPage,
            LINKS.findHome,
            LINKS.findTenant,
            ...(isUserAuthenticated ? [LINKS.messages, LINKS.myListings, LINKS.leases] : []),
            LINKS.help,
            ...(isUserAuthenticated ? [LINKS.logout] : [LINKS.login, LINKS.register]),
            LINKS.sitemap,
          ],
        },
        {
          links: [LINKS.pricing, LINKS.ourOffer, LINKS.termsAndConditions],
        },
      ],
    },

    qasa: {
      logo: StyledQasaLogo,
      linkGroups: [
        {
          links: [
            LINKS.startPage,
            LINKS.findHome,
            LINKS.findTenant,
            LINKS.findVacation,
            ...(isUserAuthenticated ? [LINKS.messages, LINKS.myListings, LINKS.leases] : []),
            LINKS.help,
            ...(isUserAuthenticated ? [LINKS.logout] : [LINKS.login, LINKS.register]),
            LINKS.sitemap,
          ],
        },
        {
          links: [
            LINKS.pricing,
            LINKS.ourOffer,
            LINKS.housingRentals,
            LINKS.forCompanies,
            LINKS.termsAndConditions,
          ],
        },
        {
          links: [LINKS.blog, LINKS.careers, LINKS.pressMedia],
        },
      ],
    },

    qasa_france: {
      linkGroups: [
        {
          links: [...(isUserAuthenticated ? [LINKS.leases] : [LINKS.login]), LINKS.help],
        },
        {
          links: [LINKS.careers, LINKS.termsAndConditions, LINKS.legalMentions],
        },
      ],

      logo: StyledQasaLogo,
    },
    dotcom: {
      logo: StyledQasaLogo,
      linkGroups: [
        {
          links: [
            LINKS.startPage,
            LINKS.findHome,
            LINKS.findTenant,
            LINKS.help,
            ...(isUserAuthenticated ? [LINKS.logout] : [LINKS.login, LINKS.register]),
          ],
        },
        {
          links: [LINKS.careers],
        },
      ],
    },
  }

  return brandConfig[currentBrand]
}
